.features-head img,
.docs-head img{
    width:200px;
    justify-self: flex-end;
}

.features-subhed img {
    width: 200px;
    justify-self: flex-end;
    
}

.features-main .card > i {
    margin-right: 20px;
}
.features-main .cuadrilla {
    padding: 30px;
}

.features-main .cuadrilla > *:first-child { // afecta al primer elemento dentro del features-main / cuadrilla
    grid-column: 1/span 3;
    
}
.features-main .cuadrilla > *:nth-child(2) { // afecta al primer elemento dentro del features-main / cuadrilla
    grid-column: 1/span 2;
    
}

