
.loader-container {
	height: 100vh;
	text-align: center;
	background: transparent;
}

.loader-container p {
	margin-top: 0;
}

.loader {
	display: flex;
	justify-content: center;
	max-width: 300px;
	margin: auto;
}

.blob1 {
	background: $azul-brillante;
	border-radius: 50%;
	margin: 25px;
	height: 20px;
	width: 20px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 1s infinite;
}

.blob2 {
	background: $gris-claro;
	border-radius: 50%;
	margin: 25px;
	height: 20px;
	width: 20px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 1.5s infinite;
}


.blob3 {
	background: $azul-medio;
	border-radius: 50%;
	margin: 25px;
	height: 20px;
	width: 20px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}