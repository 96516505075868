
.stats {
    padding-top: 100px;
    animation: deslizarDesdeAbajo 1s ease-in;
}

.stats-heading {
    max-width: 500px;
    margin: auto;
}

.stats .cuadrilla h3{
    font-size: 35px;
}

.stats .cuadrilla p {
    font-size: 20px;
}