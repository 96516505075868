 
.showcase {
    height: 500px;
    background-color: $azul-brillante;
    color: white;
    position: relative; // si es necesario posicionar algo absolute dentro de este elemento
}

.showcase h1 {
    font-size: 40px;
}

.showcase p {
    margin: 20px 0;
}   

.showcase .cuadrilla { // sobreescribe el estilo predeterminado de la cuadrilla para el showcase
    grid-template-columns: 55% 45%;
    gap: 30px;
    overflow: visible;
}

.showcase-texto {
    animation: deslizarDesdeIzquierda 1s ease-in;
}

.showcase-form {
    position: relative; // permite establecer las propiedades top, bottom, left y right con un valor fijo
    top: 60px;
    height: 550px;
    width: 400px;
    padding: 40px;
    z-index: 100; // asegura que siempre estre al frente???? 
    justify-self: flex-end;
    animation: deslizarDesdeDerecha 1s ease-in;
}
.showcase-form .form-control {
    margin: 30px 0;
}

.showcase-form input[type='password'],
.showcase-form input[type='text'],
.showcase-form input[type='email'],
input[type='text'],
input[type='password'],
input[type='email'],
select {
    border: 0;
    border-bottom: 1px solid $gris-claro;
    width: 100%;
    padding: 3px;
    font-size: 16px;
}

.showcase-form, input:focus,select,button {
    outline: none;
}

input:required:focus {
    border: 1px solid red;
    outline: none;
  }
  

.showcase::before,
.showcase::after {
    content: '';
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background: white;
    transform: skewY(-3deg);
    -webkit-transform: skewY(-3deg);
    -moz-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
}