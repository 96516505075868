
// media query place at the end of the last imported file
/* Tablets o menos resolucion */
@media( max-width: 768px){
    .cuadrilla,
    .showcase .cuadrilla,
    .stats .cuadrilla,
    .cli .cuadrilla,
    .cloud .cuadrilla,
    .features-main .cuadrilla,
    .docs-main .cuadrilla
     {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .showcase {
        height: auto;
    }
    .showcase-texto {
        text-align: center;
        margin-top: 40px;
    }

    .showcase-form {
        justify-self: center;
        margin: auto;
    }

    .cli .cuadrilla > *:first-child { // afecta al primer elemento dentro del cli / cuadrilla
        grid-column: 1;
        grid-row: 1;
    }

    .features-head,
    .features-subhead,
    .docs-head {
        text-align: center;
    }

    .features-head img,
    .features-subhead img,
    .docs-head img {
        justify-self: center;
    }
    
    .features-main .cuadrilla > *:first-child,
    .features-main .cuadrilla > *:nth-child(2) {
        grid-column: 1;
    }

   .shopcart, .checkout {
       margin: 0 2rem;
   }
    
   .shopcart .chartcontent div {
       display: block;
   }

}

/* Mobile */
@media (max-width: 500px) {
    
    .navbar {
        height: 110px;
    }

    .navbar a {
        padding: 0;
    }
    .navbar .flexible {
        flex-direction: column; // colocal todo debajo, en vez de a lo ancho
    }

    .navbar ul {
        padding: 10px;
        background-color: rgba(0, 0 ,0, 0.1 );
        }
    .showcase-form {
        width: 300px;
    }
}

/* Animations */

@keyframes deslizarDesdeIzquierda {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes deslizarDesdeDerecha{
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}


@keyframes deslizarDesdeArriba{
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes deslizarDesdeAbajo{
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateX(0);
    }
}