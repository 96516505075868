.modal-window {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-window-content {
    position: relative;
    color: white;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(106, 104, 104,0.4); /* Fallback color */
    padding: 8em 4em 8em 4em;
}


.modal-window-close-btn {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.modal-window-close-btn:hover,
.modal-window-close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.new-modal {
    border: 1px solid black;
    margin: auto;
    padding: 1em;
    max-width: 60ch;
    & > * {
        margin: 0 0 0.5rem 0;
    }
}
.new-modal::backdrop {
    background:  linear-gradient(35deg,white,black);
    opacity: 0.4;
}