/* Docs */

.docs-main h3 {
    margin: 20px 0;
}

.docs-main .cuadrilla {
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
}

.docs-main nav li {
    font-size: 17px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px #ccc solid;
}


.docs-main a:hover {
    font-weight: bold;
}