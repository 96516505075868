.shopcart {
    margin: auto;
    max-width: 950px;
}

.chartcontent .rowitm {
    border: 1px solid $gris-claro;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}


.chartcontent .rowitm > *:first-child {
    font-weight: bolder;
    width: 20%;
}

.chartcontent .rowitm > *:nth-child(2) {
    width: 75%
}

.shopcart > *:nth-child(2) {
    margin-bottom: 1rem;
}
