
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: $lato-fuente;
    color: $oscuro-fuerte;
    line-height: 1.6; // spread out entre elementos
    font-weight: $negritas-medio;
}

ul {
    list-style-type: none;
}

a { // quita la linea de vinculo de abajo
    text-decoration: none;
    color: $oscuro-fuerte;
    cursor: pointer;
}

h1, h2 { 
    font-weight: $negritas-medio ; // le quita un poco el formato negritas
    line-height: 1.2; // le da un poco menos espacios entre lineas
    margin: 10px 0; // top y botton 10px - izq y derecha 0 en margen
}

h3, h4 {
    font-weight: $negritas-medio;
}

p {
    margin: 10px 0;
}

img {
    width: 100%; // imagen se mantenga en el 100% del contenedor
}

section {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

/* Clases de estilos para reutilizar*/
.container {
    max-width: $maximo-ancho-navbar; 
    margin: 0 auto; // 0 para el top /botton y auto para el left-right
    overflow: auto; // ayuda elimina la franja superior del elemento h1 definido en _base.scss (magin 10px)
    padding: 0 40px // 0 para top/botton y 40px para left-right, para cuando hace angosta la pantalla, evita que se pegue el contenido a la izquierda
}

.flexible { // convierte todos los elementos del .container en flex, para acomodar a gusto
    display: flex;
    justify-content: center; // centra todo horizontalmente
    align-items: center; // para alinear verticalmente
    height: 100%;
}



.cuadrilla {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; // anteriormente se llamada grid-gap ahora se llama gap
    justify-content: center; // alinea horizontalmente
    align-items: center; // alinea verticalmente
    height: 100%; // cuadrilla esta dentro de showcase, por ende, el height debe ocupar el 100% de showcase

}

.cuadrilla-3 {
    grid-template-columns: repeat(3, 1fr);    
}

.card {
    background-color: white;
    color: $oscuro-fuerte;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(0, 0 ,0, 0.2 );
    padding: 20px;
    margin: 10px;
}

.btn {
    display: inline-block;
    padding: 10px 30px; // 10px top/botton 30px left/right
    cursor: pointer;
    background: $azul-brillante;
    color:white;
    border: none;
    border-radius:  5px;
}

.btn-outline {
    background-color: transparent;
    border: 1px white solid;
}

.btn:hover {
    transform: scale(0.98);
}
input:disabled {
    background: #ccc;
  }

/* Background y color de botones*/
.bg-primary,
.btn-primary {
    background: $azul-brillante;
    color:white;
    
}

.bg-secondary,
.btn-secondary {
    background: $azul-medio;
    color:white;
    
}

.bg-light,
.btn-light {
    background: $gris-claro;
    color:white;
    
}
.bg-priceplan {
    background: #FBC92E;
}

.bg-dark,
.btn-dark {
    background: $azul-oscuro;
    color:white;
    
}


 /// aplica para los links en el footer
.bg-primary a,
.bg-secondary a,
.bg-dark a,
.btn-primary a,
.btn-secondary a,
.btn-dark a {
    color: white;
}
/* . - - -  */

/* Alerta  (usado en Docs*/
.alert {
    background-color: $gris-claro;
    padding: 10px 20px;
    font-weight: bold;
    margin: 15px 0;
}

.alert i{
    margin-right: 10px;
}

.alert-success {
    background-color: $alerta-success;
    color: white;
}
.alert-error {
    background-color: $alerta-error;
    color: white;
}


/* Code */
code, pre {
    background: $oscuro-fuerte;
    color: white;
    padding: 10px;
}


/* Texto y font-sizes*/

.text-primary {
    color: $azul-brillante;
}
.text-secondary {
    color: $azul-medio;
}
.text-light {
    color: $gris-claro;
}
.text-gray {
    color: #646464;
}
.text-dark {
    color: $azul-oscuro;
}

.lead {
    font-size: 20px;
}

.xs {
    font-size: 0.7rem;
}
.xs2 {
    font-size: 0.8rem;
}

.sm {
    font-size: 1rem;
}
.md {
    font-size: 2rem;
}
.lg {
    font-size: 3rem;
}
.xl {
    font-size: 4rem;
}

/*centra el texto*/
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}



/* Definicion de margenes TOP y BOTTOM*/
.my-0 {
    margin: 0rem 0;
}
.my-1 { // margin aplicado en el stats
    margin: 1rem 0;
}
.my-2 { // margin aplicado en el stats
    margin: 1.5rem 0;
}
.my-3 { // margin aplicado en el stats
    margin: 2rem 0;
}
.my-4 { // margin aplicado en el stats
    margin: 3rem 0;
}
.my-5 { // margin aplicado en el stats
    margin: 4rem 0;
}

.mx-1 { // margin aplicado en el stats
    margin-left: 1rem; margin-right: 1rem;
}
.mx-2 { // margin aplicado en el stats
    margin-left: 1.5rem; margin-right: 1.5rem;
}
.mx-3 { // margin aplicado en el stats
    margin-left: 2rem; margin-right: 2rem;
}
.mx-4 { // margin aplicado en el stats
    margin-left: 3rem; margin-right: 3rem;
}
.mx-5 { // margin aplicado en el stats
    margin-left: 4rem; margin-right: 4rem;
}

/* Definicion de padding mismo numero que el margin anterior*/
.py-0 {
    padding: 0 0;
}
.py-1 { // padding aplicado en el stats
    padding: 1rem 0;
}
.py-2 { // padding aplicado en el stats
    padding: 1.5rem 0;
}
.py-3 { // padding aplicado en el stats
    padding: 2rem 0;
}
.py-4 { // padding aplicado en el stats
    padding: 3rem 0;
}
.py-5 { // padding aplicado en el stats
    padding: 4rem 0;
}

/* Definicion de margenes  TODO ALREDEDOR*/
.m-1 { // margin aplicado en el stats
    margin: 1rem;
}
.m-2 { // margin aplicado en el stats
    margin: 1.5rem;
}
.m-3 { // margin aplicado en el stats
    margin: 2rem;
}
.m-4 { // margin aplicado en el stats
    margin: 3rem;
}
.m-5 { // margin aplicado en el stats
    margin: 4rem;
}

/* Definicion de padding  TODO ALREDEDOR*/
.p-1 { // margin aplicado en el stats
    padding: 1rem;
}
.p-2 { // padding aplicado en el stats
    padding: 1.5rem;
}
.p-3 { // padding aplicado en el stats
    padding: 2rem;
}
.p-4 { // padding aplicado en el stats
    padding: 3rem;
}
.p-5 { // padding aplicado en el stats
    padding: 4rem;
}

