
.navbar { // contenedor de las barra de navbar
    background-color: $azul-brillante; // fondo azul brillante
    color: white; // letras blancas
    height: 70px; // altura de 70 pixeles
}
.navbar .flexible {
    justify-content: space-between; // le aplica el space-between solo en el caso de estar en el navbar
    // esto permite que la clase flexible, tenga en "center" por defualt para otros componentes
}

.navbar ul {
    display: flex; // los elementos del menu
}
.navbar a {
    color: white;
    padding: 10px;
    margin: 0 5px;
}
.navbar a:hover {
    border-bottom: 2px white solid;
}

.hidden {
        visibility: hidden;
        height: 0;
}

