
.bill-table-header, .bill-table-row {
    display: flex;
    justify-content: space-between;
}
.bill-table-header {
    font-weight: 400; //  not working, need to check what's the default style for Lato font family
}

.bill-table i:hover {
    color: gray;
    cursor: pointer;
}

.bill-table p {
    text-align: center;
    width: 100%;
}

.invoice-detail p{
    margin: 0 0;
    font-size: 12px;
}

.bill-table .description {
    text-align: left;
}


.invoice-header {
    display: flex;
    justify-content: space-around;
}

.invoice-header .anet-info {
    margin-bottom: 2rem;
}
.invoice-header .anet-info i {
    color: $azul-brillante; 
}


.invoice-header .anet-name {
    margin-bottom: 0;
}
.invoice-header .anet-footnote {
    font-size: .7rem;
    margin-top: 0;
    margin-bottom: 0;
}

.invoice-header .invoice-header-col {
    width: 50%;
    border: 1px solid $gris-claro;
    border-radius: 4px;
    padding: 5px 5px;
}

.invoice-header .table1row {
    display: flex;
    justify-content: space-around;
    
}
.invoice-header .table1row p {
    margin: 2px;
    width: 30%;
}

.invoice-header .table1row .title {
    text-align: right;
    font-weight: $negritas-fuerte;
}
.invoice-header .table1row .titlecap {
    background-color: $gris-claro;  
    width: 70%;
}

.invoice-body {
    padding: 1rem;
}

.bill-summary {
    border-top: 1px solid black ;
    justify-content: flex-end;
}
.bill-summary .subtotal-label {
        margin-right: 1rem;
        font-weight: $negritas-fuerte;
}

.invoice-footer  .anet-footnote {
    font-size: .7rem;
}
