
.select-drop-container {
    position: relative;
    height: auto;
    
}
.subprod-dropdown {
    position: absolute;
    background: white;
    box-shadow: 0 3px 20px rgba(0, 0 ,0, 0.2 );
}

.subprod-drowdown-content {
    display: none;
    padding: 0 5px 0 5px;
    
}

.subprod-drowdown-content input[type='text']{
    width: 100%;
}

.subprod-dropdown-content-show {
    display: block;
}
.subprod-drowdown-content .product-list-item {
    display: none;
    cursor: pointer;
}

.product-list-item:hover{
    background-color: $azul-brillante;
    color: white;
    
}