

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;900&display=swap');

$lato-fuente: 'Lato', sans-serif;

// colores
$oscuro-fuerte: #333;
$azul-brillante: #39ACC0;
$azul-medio: #1c3fa8;
$azul-oscuro: #37474F;
$gris-claro:  #b4becb;
$alerta-success: #5cd85c;
$alerta-error: #d9534f;

// maximo del ancho del contenedor de navbar

$maximo-ancho-navbar: 1100px;

$negritas-light: 100;
$negritas-medio: 300;
$negritas-fuerte: 400;
$negritas-bold: 900;
