.languages .card {
    text-align: center;
    margin: 18px 10px; // margen especifico para las cards
    transition: transform 0.2s ease-in;
    max-width: 300px;
}

.languages .flexible {
    flex-wrap: wrap;
}

.languages .card  h4{
    font-size: 20px;
    margin-bottom: 10px;
    
}

.languages .card:hover {
    transform: translateY(-10px);
}