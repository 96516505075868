.checkout {
    margin: auto;
    max-width: 950px;
}

.checkout .checkout-form {
    border: 1px solid $gris-claro;
    
}

.checkout-form input[type='text'],input[type='checkbox'],input[type='email'],input[type='password'], select{
    margin-top: 1rem;
    padding: 1rem 1rem;
}
#paypal-button-container {
    
    text-align: center;
    margin-top: 1rem;
    
}